import axios from 'axios'

export default class iddyCryptoApi{
    constructor(data){
        const {
            accessToken,
            baseUrl,
        } = data;
        this.accessToken = accessToken;
        this.baseUrl = baseUrl;
    }

    setAccessToken(access_token){
        this.access_token = access_token;
    }

    getApiPath(apiUrl){
        return (this.baseUrl)?this.baseUrl + apiUrl:apiUrl;
    }

    getSnapshotBySymbol({
        symbol, 
        since,
        end,
        ISO8601Duration,
        future,
    }){
        let path = (future)?'/snapshot_future':'/snapshot';
        return axios.get(this.getApiPath(path), {
            params: {
                symbol, 
                since,
                end,
                ISO8601Duration,
                future: (future)?'true':'false',
            },
        });
    }

    getFundingRateHistory(symbol, since, end, {
        withSnapshotFuture = false,
    } = {}){
        return axios.get(this.getApiPath('/funding_rate_history'), {
            params: {
                symbol,
                since,
                end,
                withSnapshotFuture,
            },
        });
    }

    getSymbols(exchange){
        return axios.get(this.getApiPath('/symbol'), {
            params:{
                exchange,
            }
        })
    }

    getStrategyStatisticsReport(exchange){
        return axios.get(this.getApiPath('/strategy_statistics_report'), {
            params:{
                exchange,
            }
        })
    }
}