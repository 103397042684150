import {
    Container,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Box,
    CircularProgress,
} from '@material-ui/core'
import { 
    ToggleButton,
    ToggleButtonGroup,
} from '@material-ui/lab';
import { LocalDrinkSharp, SelectAllTwoTone, TramOutlined } from '@material-ui/icons';
import { useState, useEffect, useContext, useReducer, createContext, useRef, useCallback, useLayoutEffect } from 'react';
import styles from './SFAMainContainer.module.scss'
import _, { set } from 'lodash';
import HookFormInput from "./HookFormInput";
import HookFormSelect from "./HookFormSelect";
import HookGridForm from "./HookGridForm";

import FundingRatePerformance from '../utilities/FundingRatePerformance'
import FundingRateSimulator from '../utilities/FundingRateSimulator'
import iddyCryptoApi from '../api/iddyCryptoApi'
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

import lightGreen from '@material-ui/core/colors/lightGreen';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { EXCHANGES_SELECT } from "../constants/Exchange";
import { PAIRS_SELECT } from "../constants/Pair";

import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  } from 'chart.js';
  
  dayjs.extend(customParseFormat);
  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  );
  

export const SFASimulatorContext = createContext();
const iddyApi = new iddyCryptoApi({});

export function SFASimulatorReducer(state, action) {
    const {type, payload} = action;
    switch(type){
        case 'SET_PERFORMANCE':
            return {
                ...state, 
                performance: payload,
            };
        case 'SET_STRATEGY':
            return {
                ...state,
                strategy: payload,
            }
        case 'UPDATE_FORM_DATA':
            return {
                ...state,
                formData: payload,
            }
        case 'START_LOADING':
            return {
                ...state,
                isLoading: true,
                performance: null,
                strategy: null,
            }
        case 'END_LOADING':
            return {
                ...state,
                isLoading: false,
            }
        case 'FETCH_PAIRS':
            return {
                ...state,
                pairs: payload,
            }
        default:
            throw new Error();
    }
}

function SFAMainContainer(){
    const reducer = useReducer(SFASimulatorReducer, {
        isLoading: false,
        formData: {
            totalAmount: 10000,
            exchangeId: 'binance',
            pair: 'BTC/USDT',
            leverage: 3,
            reserveRate: 0.02,
            futureAmountPrecision: 3,
    
            // for testing
            sinceDisplay: '2021-01-01',
            startSpotMarketingPrice: null,
            startFutureMarketingPrice: null,
            endDisplay: '2021-01-30',
            endSpotMarketingPrice: null,
            endFutureMarketingPrice: null,
        },
        pairs: [

        ],
    });
    const {
        isLoading,
        strategy,
        performance,
    } = reducer[0];
    console.log('SFCMainContainer state ', reducer[0]);

    return <>
        <SFASimulatorContext.Provider value={reducer}>
            <div>
                <SFASimulatorForm />
            </div>
            { 
                isLoading && <>
                    <div className={styles.loadingContainer}>
                        <CircularProgress />                
                    </div>
                </>
            }

            {
                !isLoading && strategy && <>
                    <div>
                        <SFAStrategyStatistics />
                    </div>
                </>
            }

            {
                !isLoading && performance && <>
                    <div>
                        <SFAROIFormula />
                    </div>
                    <div>
                        <SFAROIChart />
                    </div>
                </>
            }

        </SFASimulatorContext.Provider>
    </>;
}

function SFASimulatorForm(){
    const [{formData, pairs}, dispatch] = useContext(SFASimulatorContext);
    const [exchangeId, setExchangeId] = useState(formData.exchangeId);
    const exchangeOptions = EXCHANGES_SELECT;
    const pairOptions = pairs.map( v => ({
        name: v.symbol,
        value: v.symbol,
    }));
    useEffect(() => {
        iddyApi.getSymbols(exchangeId).then( rs => {
            if(rs){
                let pairs = rs.data;
                dispatch({type: 'FETCH_PAIRS', payload: pairs})    
            }
        });
    }, [exchangeId, dispatch])
    const onExchangeUpdate = async (e) => {
        const value = e.target.value;
        setExchangeId(value);
    }
    const onStartDateUpdate = async (e, {form}) => {
        const value = e.target.value;
        const time = value + ' 00:00:00';
        const pair = form.getValues('pair');
        const simulator = FundingRateSimulator.init({pair, cryptoApi: new iddyCryptoApi({})});
        if( dayjs(value, 'YYYY-MM-DD', true).isValid() ){
            let spotPrice = await simulator.getLatestSnapshotMarketingPriceByTime(time, false);
            let futurePrice = await simulator.getLatestSnapshotMarketingPriceByTime(time, true);
            if(spotPrice){
                form.setValue('startSpotMarketingPrice', spotPrice)
            }
            if(futurePrice){
                form.setValue('startFutureMarketingPrice', futurePrice)
            }
        }
    }
    const onEndDateUpdate = async (e, {form}) => {
        const value = e.target.value;
        const time = value + ' 23:59:00';
        const pair = form.getValues('pair');
        const simulator = FundingRateSimulator.init({pair, cryptoApi: new iddyCryptoApi({})});
        if( dayjs(value, 'YYYY-MM-DD', true).isValid() ){
            let spotPrice = await simulator.getLatestSnapshotMarketingPriceByTime(time, false);
            let futurePrice = await simulator.getLatestSnapshotMarketingPriceByTime(time, true);
            if(spotPrice){
                form.setValue('endSpotMarketingPrice', spotPrice)
            }
            if(futurePrice){
                form.setValue('endFutureMarketingPrice', futurePrice)
            }
        }
    }

    const formGroups = [
        {
            name: 'Public Variables',
            children: [
                <HookFormInput name="totalAmount" label="Investment" />,
                <HookFormSelect name="exchangeId" label="Exchange" options={exchangeOptions} onChange={onExchangeUpdate} />,
                <HookFormSelect name="pair" label="Pair" options={pairOptions} />,
                <HookFormInput name="leverage" label="Leverage" />,
            ]
        },
        {
            name: 'Internal Variables',
            children: [
                <HookFormInput name="reserveRate" label="Reserve Rate" />,
                <HookFormInput name="futureAmountPrecision" label="Future Max Decimal Point" />,
            ]
        },
        {
            name: 'Period (UTC+8)',
            children: [
                <HookFormInput name="sinceDisplay" label="From Date" onChange={onStartDateUpdate}/>,
                <HookFormInput name="startSpotMarketingPrice" label="From Date Spot Marketing Price" InputLabelProps={{shrink:true}}/>,
                <HookFormInput name="startFutureMarketingPrice" label="From Date Future Marketing Price" InputLabelProps={{shrink:true}}/>,
                <></>,
                <HookFormInput name="endDisplay" label="To Date" onChange={onEndDateUpdate}/>,
                <HookFormInput name="endSpotMarketingPrice" label="To Date Spot Marketing Price" InputLabelProps={{shrink:true}}/>,
                <HookFormInput name="endFutureMarketingPrice" label="To Date Future Marketing Price" InputLabelProps={{shrink:true}}/>,
                <></>,
            ]
        },
    ]

    const onFormSubmit = async (formData) => {
        console.log('onFormSubmit');
        [
            'totalAmount', 
            'leverage', 
            'reserveRate', 
            'futureAmountPrecision', 
            'endFutureMarketingPrice',
            'endSpotMarketingPrice',
            'startFutureMarketingPrice',
            'startSpotMarketingPrice',
        ].forEach((v) => {
            formData[v] = _.toNumber(formData[v]);
        });
        formData.since = formData.sinceDisplay + ' 00:00:00';
        formData.end = formData.endDisplay + ' 23:59:00';

        try{
            dispatch({type: 'START_LOADING'});
            dispatch({type: 'UPDATE_FORM_DATA', payload: formData});
            let simulatorParam = {...formData};
            simulatorParam.cryptoApi = new iddyCryptoApi({})
            const simulator = FundingRateSimulator.init(simulatorParam);
            console.log(simulator);
            simulator.spotTradingFee = await simulator.fetchSpotTradingFee();
            simulator.futureTradingFee = await simulator.fetchFutureTradingFee();
            let result = await simulator.fetchPerformanceSimulateParam();
    
            console.log('Simulator: ', simulator);
            console.log('performanceParam: ', result);
            dispatch({type: 'SET_PERFORMANCE', payload: FundingRatePerformance.init(result)});
            dispatch({type: 'SET_STRATEGY', payload: _.head(result.tradingHistory)});
            dispatch({type: 'END_LOADING'});
        }catch(e){
            dispatch({type: 'END_LOADING'});
            console.error(e);
        }
    }

    return <Box>
        <HookGridForm groups={formGroups} defaultValues={formData} onSubmit={onFormSubmit}/>
    </Box>;
}

function SFAStrategyStatistics(props){
    const [{strategy}, ] = useContext(SFASimulatorContext);
    console.log('RENDER Strategy', strategy);
    const gridItems = [
        {name: 'Total Amount', key: 'totalAmount'},
        {name: 'Remaining', key: 'remaining'},
        {name: 'Spot Allocation', key: 'spotAllocation'},
        {name: 'Future Allocation', key: 'futureAllocation'},
        {name: 'Spot Transfer', key: 'spotTransfer'},
        {name: 'Future Transfer', key: 'futureTransfer'},
        {name: 'Spot Utilized', key: 'spotUtilized'},
        {name: 'Future Utilized', key: 'futureUtilized'},
        {name: 'Spot Amount', key: 'spotAmount'},
        {name: 'Future Amount', key: 'futureAmount'},
        {name: 'Spot Market Price', key: 'spotMarketPrice'},
        {name: 'Future Market Price', key: 'futureMarketPrice'},
    ]

    if(_.isEmpty(strategy)){
        return <></>;
    }
    let stats = _.clone(strategy);
    stats = _.forIn(stats, (value, key) => {
        if(_.isFinite(value)){
            stats[key] = _.round(value, 4);
        }
    })

    return <Box>
        <Typography variant="h5">Strategy</Typography>
        <Box>
            <Grid container spacing="3">
                {gridItems.map(v => <>
                    <Grid key={v.key} item md={3} xs={6} className={styles.strategyItemContainer}>
                        <Typography className={styles.itemValue} variant="h5">{stats[v.key]}</Typography>
                        <Typography className={styles.itemTitle} variant="body2" component="p">{v.name}</Typography>
                    </Grid>
                </>)}
            </Grid>
        </Box>
    </Box>;
}

function SFAROIFormula(){
    const [{performance}, ] = useContext(SFASimulatorContext);
    const [url, setUrl] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        if(performance){
            const makeTextFile = function (text) {
                var data = new Blob([text], {type: 'text/plain'});
                return window.URL.createObjectURL(data);
            };
     
            let rs = performance.convertToPortfolioTransactions();
            const textFile = makeTextFile(JSON.stringify(rs, null, 2));
            setUrl(textFile);
            window.downloadCSV = () => {
                ref.current.click();
            }
            return () => {
                window.URL.revokeObjectURL(textFile);
            }
        }
    }, [performance, ref])

    if(_.isEmpty(performance)){
        return <></>;
    }

    let handlingFee = performance.calculateHandlingFee();
    let spotFutureDiff = performance.calculateSpotFutureDiff();
    let fundingRateEarned = performance.calculateFundingRateEarned();
    let totalOutcome = handlingFee + spotFutureDiff + fundingRateEarned;

    return <Box>
        <a ref={ref} style={{display: 'none'}} href={url} download="trans.txt">Download</a>
        <Typography variant="h5">ROI</Typography>
        <Box>
            <Grid container spacing="3" direction="row" justify="center" alignItems="center">

                <Grid item md={3} xs={6} className={styles.strategyItemContainer}>
                    <Grid container spacing="3" direction="row" justify="center" alignItems="center">
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                            <Typography className={styles.itemValue} variant="h5">{handlingFee.toLocaleString()}</Typography>
                            <Typography className={styles.itemTitle} variant="body2" component="p">Handling Fee</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={3} xs={6} className={styles.strategyItemContainer}>
                    <Grid container spacing="3" direction="row" justify="center" alignItems="center">
                        <Grid item xs={2}> + </Grid>
                        <Grid item xs={10}>
                            <Typography className={styles.itemValue} variant="h5">{spotFutureDiff.toLocaleString()}</Typography>
                            <Typography className={styles.itemTitle} variant="body2" component="p">Spot Future Diff</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={3} xs={6} className={styles.strategyItemContainer}>
                    <Grid container spacing="3" direction="row" justify="center" alignItems="center">
                        <Grid item xs={2}> + </Grid>
                        <Grid item xs={10}>
                            <Typography className={styles.itemValue} variant="h5">{fundingRateEarned.toLocaleString()}</Typography>
                            <Typography className={styles.itemTitle} variant="body2" component="p">Funding Rate Earned</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={3} xs={6} className={styles.strategyItemContainer}>
                    <Grid container spacing="3" direction="row" justify="center" alignItems="center">
                        <Grid item xs={2}> = </Grid>
                        <Grid item xs={10}>
                            <Typography className={styles.itemValue} variant="h5">{totalOutcome.toLocaleString()}</Typography>
                            <Typography className={styles.itemTitle} variant="body2" component="p">Total Profit</Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    </Box>
}


function SFAROIChart(){
    const [{performance}, ] = useContext(SFASimulatorContext);
    const [chart, setChart] = useState(null);
    const [groupBy, setGroupBy] = useState('DAY');
    const handleGroupBy = (event, newGroupBy) => {
        setGroupBy(newGroupBy);
    }
    
    useEffect(() => {
        if(chart && performance){
            let {labels, datasets} = performance.calculateROIChartData({
                groupBy
            });
            chart.data.labels = labels;
            chart.data.datasets.forEach(dataset => {
                dataset.data = datasets[dataset.key] ?? [];
            });
            let max = _.max([...datasets.spotPrice, ...datasets.futurePrice]);
            let min = _.min([...datasets.spotPrice, ...datasets.futurePrice]);
            let scales = chart.options.scales;
            scales.spotPrice.suggestedMax = max;
            scales.spotPrice.suggestedMin = min;
            scales.futurePrice.suggestedMax = max;
            scales.futurePrice.suggestedMin = min;
            console.log(max);
            console.log(min);
            console.log(chart.data);
            console.log(chart.options);
            chart.update();
        }
    }, [chart, performance, groupBy])

    const ref = useCallback(node => {
          const data = {
            labels: [],
            datasets: [
                {
                    key: 'spotPrice',
                    label: 'Spot Market Price',
                    backgroundColor: blue[500],
                    borderColor: blue[500],
                    data: [],
                    spanGaps: true,
                    stack: 'combined',
                    yAxisID: 'spotPrice',
                },
                {
                    key: 'futurePrice',
                    label: 'Future Market Price',
                    backgroundColor: red[500],
                    borderColor: red[500],
                    data: [],
                    spanGaps: true,
                    stack: 'combined',
                    yAxisID: 'futurePrice',
                },
                {
                    key: 'roi',
                    label: 'ROI',
                    backgroundColor: lightGreen[500],
                    borderColor: lightGreen[500],
                    data: [],
                    spanGaps: true,
                    type: 'bar',
                    stack: 'combined',
                    yAxisID: 'roi',
                },
            ]
          };

          const config = {
            // tooltips: {
            //     enabled: false,
            //     custom: CustomTooltips,
            //     intersect: true,
            //     mode: 'index',
            //     position: 'nearest',
            //     callbacks: {
            //       labelColor: function(tooltipItem, chart) {
            //         return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
            //       }
            //     }
            //   },
            type: 'line',
            data,
            options: {
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                stacked: false,
                scales: {
                    roi: {
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    spotPrice: {
                        type: 'linear',
                        position: 'right',
                    },
                    futurePrice: {
                        type: 'linear',
                        position: 'right',
                        display: false,
                    },
                },
                elements: {
                    point: {
                        radius: 2,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3,
                    },
                },
            }
        };

        if (node !== null){
            const canvas = node;
            // init chart object
            const chart = new Chart(
                canvas, 
                config,
            );
            setChart(chart);
        }
    }, [])

    return <>
        <div>
            <div className={styles.chartToggleContainer}>
                <ToggleButtonGroup
                    value={groupBy}
                    exclusive
                    onChange={handleGroupBy}
                    aria-label="Daily | Monthly"
                    >
                    <ToggleButton value="DAY" aria-label="Daily">
                        Daily
                    </ToggleButton>
                    <ToggleButton value="MONTH" aria-label="Monthly">
                        Monthly
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <canvas ref={ref}></canvas>
        </div>
    </>;
}

export default SFAMainContainer;