import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';

import { useHistory } from "react-router-dom";

const categories = [
  {
    id: 'm1',
    name: 'Crypto',
    children: [
      { id: 'mi1', name: 'ROI Simulator', link:'/roi-simulator', icon: <DashboardIcon />},
      { id: 'mi2', name: 'Funding Fee (By Exchange)', link:'/funding-fee-exchange', icon: <DashboardIcon /> },
      { id: 'mi3', name: 'Funding Fee (By Pair)', link: '/funding-fee-pair', icon: <DashboardIcon /> },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, ...other } = props;
  let history = useHistory();
  let [activeMenuItemId, setActiveMenuItemId] = useState('mi1');
  const onCategoriesChildClick = (child) => {
    return () => {
      if(child){
        history.push(child.link);
        setActiveMenuItemId(child.id);  
      }
    }
  }
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          Menu
        </ListItem>
        {categories.map(({ id, name, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {name}
              </ListItemText>
            </ListItem>
            {children.map((child) => (
              <ListItem
                key={child.id}
                button
                className={clsx(classes.item, (activeMenuItemId === child.id) && classes.itemActiveItem)}
                onClick={onCategoriesChildClick(child)}
              >
                <ListItemIcon className={classes.itemIcon}>{child.icon}</ListItemIcon>
                <ListItemText
                classes={{
                    primary: classes.itemPrimary,
                }}
                >
                {child.name}
                </ListItemText>

              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);