import { TextField } from '@material-ui/core';
import { useForm, Controller, useController } from "react-hook-form";

function HookFormInput(props){
    const {
        type = 'text',
        control,
        form,
        name, 
        label, 
        defaultValue,
        onChange,
        onBlur,
    } = props;
    const {
        field: { ref, ...inputProps },
    } = useController({
        name,
        control,
        rules: { required: true },
        defaultValue,
    });
    const handleOnChange = (e) => {
        inputProps.onChange(e)
        if(typeof onChange === 'function'){
            onChange(e, {
                form
            });
        }
    }
    const handleOnBlur = (e) => {
        inputProps.onBlur(e)
        if(typeof onBlur === 'function'){
            onBlur(e, {
                form
            });
        }
    }
    switch(type){
        case 'text':
        default:
            return <TextField 
                    name={name} 
                    label={label} 
                    defaultValue={defaultValue} 
                    variant="outlined"
                    fullWidth
                    {...props}
                    {...inputProps} 
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                    inputRef={ref}
                    />
    }
}

export default HookFormInput;