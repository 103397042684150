import {
    Container,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Box,
} from '@material-ui/core'
import { LocalDrinkSharp } from '@material-ui/icons';
import { useState, useEffect } from 'react';
import styles from './ROISimulator.module.scss'
import _ from 'lodash';
import SFAMainContainer from '../components/SFAMainContainer';

function ROISimulator(){
    const strategyOptions = [
        {
            name: "SFA",
            value: "SFA",
            component: SFAMainContainer,
        },
    ];
    const [strategy, setStrategy] = useState();

    const { 
        component: Simulator 
    } = strategyOptions.find(v => v.value === strategy) ?? {};
    
    const onStrategyChange = (value) => {
        setStrategy(value);
    }
    
    return <>
        <Container className={styles.container}>
            <div>
                <Typography variant='h4'>
                    ROI Simulator
                </Typography>
            </div>
            <div>
                <StrategyForm className={styles.strategyForm} options={strategyOptions} onChange={onStrategyChange} />
            </div>
            {Simulator && <Simulator />}
        </Container>
    </>;
}

function StrategyForm(props){
    const {
        options = [],
        onChange = () => {},
    } = props;
    const [strategy, setStrategy] = useState(_.first(options).value);
    const handleChange = (e) => {
        setStrategy(e.target.value);
    }
    
    useEffect(() => {
        onChange(strategy);
    }, [strategy])
    
    return <>
    <Grid container spacing={3} >
        <Grid item xs={6} sm={3}>
            <FormControl style={{width:'100%'}}>
                <InputLabel htmlFor="strategy">Strategy</InputLabel>
                <Select
                    labelId="strategy"
                    id="strategy"
                    value={strategy}
                    onChange={handleChange}
                    >
                    {options.map( ({name, value}) => {
                        return <MenuItem key={value} value={value}>{name}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
    </Grid>
    </>;
}

export default ROISimulator;