import {
    Container,
    Paper,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Box,
    Button,
    TextField,
    CircularProgress,
} from '@material-ui/core'
import { LocalDrinkSharp } from '@material-ui/icons';
import { useState, useEffect, createContext, useReducer, useContext } from 'react';
import styles from './ROISimulator.module.scss'
import _ from 'lodash';
import { EXCHANGES_SELECT, DEFAULT_EXCHANGE_ID } from "../constants/Exchange";
import { PAIRS_SELECT } from "../constants/Pair";
import { 
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from '@material-ui/data-grid';

import FundingRatePerformance from '../utilities/FundingRatePerformance'
import FundingRateSimulator from '../utilities/FundingRateSimulator'
import iddyCryptoApi from '../api/iddyCryptoApi'
import dayjs from "dayjs";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Table } from 'antd'
import { CSVLink } from 'react-csv'
import Chart from '../components/Chart'


export const FundingFeeExchangeContext = createContext();
export function SFASimulatorReducer(state, action) {
    const {type, payload} = action;
    switch(type){
        case 'SELECT_EXCHANGE':
            return {
                ...state, 
                exchangeId: payload,
            };
        case 'UPDATE_DATA':
            return {
                ...state,
                data: payload,
            }
        case 'START_LOADING':
            return {
                ...state,
                isLoading: true,
                data: null,
            }
        case 'STOP_LOADING':
            return {
                ...state,
                isLoading: false,
            }
        default:
            throw new Error();
    }
}

const defaultStrategy = {
    amount: 10000,
}

const iddyApi = new iddyCryptoApi({});

function FundingFeeExchange(){
    
    const reducer = useReducer(SFASimulatorReducer, {
        exchangeId: DEFAULT_EXCHANGE_ID,
        since: dayjs().subtract(12, 'month').format('YYYY-MM-01'),
        end: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
        isLoading: false,
        data: null,
    });
    const [{
        exchangeId,
        since,
        end,
        data,
        isLoading,
    }, dispatch] = reducer;

    useEffect(() => {
        const fetchData = async (pair) => {
            const simulatorParam = {
                exchangeId,
                pair,
                since,
                end,
                cryptoApi: iddyApi,
            };
            const simulator = FundingRateSimulator.init(simulatorParam);
            let performanceResult = await simulator.fetchPerformanceSimulateTableItemParam();
            const performance = FundingRatePerformance.init(performanceResult);
            let splitResult = await simulator.splitSpotAndFutureAmountPerMonth(defaultStrategy);
            let latestFundingFeeRate = _.chain(performance.fundingRateHistory).last().at('fundingRate').head().value();
            let apr7 = performance.calculateEndAPR(7).value;
            let apr30 = performance.calculateEndAPR(30).value;
            let apr180 = performance.calculateEndAPR(180).value;
            let roiArr = performance.calculateFundingRateROIByMonth(splitResult);

            let result = {
                id: pair,
                pair,
                latestFundingFeeRate,
                apr7,
                apr30,
                apr180,
            }
            roiArr.forEach(v => {
                let diff = dayjs(v.time).diff(since, 'month');
                result['roi_m'+diff] = v.roi;
            })
            return result;
        }

        dispatch({type: 'START_LOADING'});
        iddyApi.getStrategyStatisticsReport(exchangeId).then(res => {
            dispatch({type: 'UPDATE_DATA', payload: res.data});
            dispatch({type: 'STOP_LOADING'});
        }).catch(err => {
            console.error(err);
            dispatch({type: 'STOP_LOADING'});
        })
    }, [exchangeId, dispatch, end, since]);

    return <>
        <FundingFeeExchangeContext.Provider value={reducer}>
            <Container maxWidth={false} className={styles.container}>
                <div>
                    <Grid container spacing={3} alignItems="baseline">
                        <Grid item xs={12} sm={7}>
                            <Typography variant='h4'>
                                Funding Fee (By Exchange) [Assume $10,000 amount]
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <FundingFeeExchangeTools />
                        </Grid>
                    </Grid>
                </div>
                { 
                    isLoading && <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <CircularProgress />                
                        </div>
                    </>
                }
                {
                    !isLoading && data && <>
                        <div>
                            <FundingFeeExchangeTable since={since} end={end} data={data}/>
                        </div>
                    </>
                }

            </Container>
        </FundingFeeExchangeContext.Provider>
    </>;
}

function FundingFeeExchangeTools(){
    const [{exchangeId}, dispatch] = useContext(FundingFeeExchangeContext);
    const options = EXCHANGES_SELECT;

    const handleChange = (event) => {
      dispatch({'type': 'SELECT_EXCHANGE', payload: event.target.value});
    };
    return <>
        <Grid container   
            justify="flex-end"
            alignItems="center"
            spacing={3}
        >
            {/* <Grid item xs>
                <Button variant="contained" color="primary">
                    Export to CSV
                </Button>
            </Grid> */}
            <Grid item xs>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',

                }}>
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Exchange"
                        value={exchangeId}
                        onChange={handleChange}
                        variant="outlined"
                        >
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </Grid>
        </Grid>
    </>
}

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

function FundingFeeExchangeTable({
    since,
    end,
    data
}){
    const [dialog, setDialog] = useState({open:false, pair:null});
    if(!data){
        return <></>;
    }
    const fundingFeeRateFormatter = (value) => {
        let format = parseFloat(parseFloat(value * 100).toPrecision(4))
        return (_.isFinite(format)) ? format + '%': '/';
    }
    const timestampFormatter = (value) => {
        return dayjs(value).format('YYYY-MM-DD HH:mm');
    }
    const percentFormatter = (value) => {
        let format = _.round(value * 100, 2);
        return (_.isFinite(format)) ? format + '%': '/';
    }
    const renderOpenDialogButton = (value) => {
        let pair = value;
        const onClick = () => {
            setDialog({
                open: true,
                pair: pair,
            })
        }
        return <Button color="primary" onClick={onClick}>Open</Button>;
    }
    const columns = [
        { dataIndex: 'id', title: 'Chart', fixed: 'left', width: 80, render: renderOpenDialogButton},
        { dataIndex: 'pair', title: 'Pair', fixed: 'left', width: 120 },
        { dataIndex: 'latestFundingFeeRate', title: 'Latest Funding Fee', width: 150, render:fundingFeeRateFormatter },
        { dataIndex: 'latestFundingFeeTime', title: 'Latest Fee Time (UTC+8)', width: 150, render:timestampFormatter },
        { dataIndex: 'apr7', title: '7D APR', width: 120, render:percentFormatter },
        { dataIndex: 'apr30', title: '30D APR', width: 120, render:percentFormatter },
        { dataIndex: 'apr180', title: '180D APR', width: 120, render:percentFormatter },
    ];
    
    const diff = dayjs(end).diff(since, 'month');
    for(let i = 0; i <= diff; i++){
        let month = dayjs(since).date(1).add(i, 'month');
        columns.push({ dataIndex: 'roi_m'+i, title: month.format('MMM YYYY') + ' ROI', width: 140, render: percentFormatter});
    }

    columns.forEach(col => {
        col.key = col.dataIndex;
        let sortfn = (key) => {
            return (ai,bi) => {
                let a = ai[key];
                let b = bi[key];
                if(_.isFinite(a) && _.isFinite(b)){
                    return a - b;
                } else if (_.isFinite(a)){
                    return 1;
                } else if (_.isFinite(b)){
                    return -1;
                }
                if(typeof a === 'string' || typeof b === 'string'){
                    return a.localeCompare(b)
                }
                return 0;
            }
        }
        if(col.dataIndex !== 'id'){
            col.sorter = sortfn(col.dataIndex);
            col.sortDirections = ['descend', 'ascend'];
        }
    })

    const handleClose = () => {
        setDialog({
            open: false,
            pair: null,
        })
    }

    // Prepare export CSV data
    let csvData = _.cloneDeep(data);
    let csvHeaders = columns.map( col => {
        return {
            label: col.title,
            key: col.dataIndex,
            render: col.render,
        };
    })
    csvHeaders = _.drop(csvHeaders);
    csvHeaders.forEach( ({key, render}) => {
        if(typeof render === 'function'){
            csvData.forEach(v => {
                if(v[key]){
                    let renderValue = render(v[key]);
                    if(typeof renderValue !== 'object'){
                        v[key] = renderValue.toString();
                    }
                }
            })
        }
    })
    let csvName = 'funding_fee_' + dayjs().format('YYYYMMDDhhmm');

    return <>
        <div style={{height:720, width:'100%', marginBottom: 30}}>
            <CSVLink filename={csvName} data={csvData} headers={csvHeaders}>
                <Button color="primary" style={{ marginBottom: 0 }}>
                    Export to CSV
                </Button>
            </CSVLink>
            <Table 
                dataSource={data}
                columns={columns}
                scroll={{ y: 600,x: 600 }}
                pagination={{pageSize: 100}}
                />
        </div>
        <Dialog
            open={dialog.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='lg'
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">{dialog.pair + ' Funding Rate History'}</DialogTitle>
            <DialogContent>
                <Chart symbol={dialog.pair}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

/**
 * @material-ui/data-grid is not support Column pinning at now
 * We try to change the table library to antd which support fixed columns
 */
// function FundingFeeExchangeTableBackup({
//     since,
//     end,
//     data
// }){
//     const [dialog, setDialog] = useState({open:false, pair:null});
//     if(!data){
//         return <></>;
//     }
//     const fundingFeeRateFormatter = ({value}) => {
//         let format = parseFloat(parseFloat(value * 100).toPrecision(4));
//         return (_.isFinite(format)) ? format : '';
//     }
//     const percentFormatter = ({value}) => {
//         let format = _.round(value * 100, 2);
//         return _.isFinite(format) ? format : '';
//     }
//     const renderOpenDialogButton = (params) => {
//         let pair = params.row.pair;
//         const onClick = () => {
//             setDialog({
//                 open: true,
//                 pair: pair,
//             })
//         }
//         return <Button color="primary" onClick={onClick}>Open</Button>;
//     }
//     const columns = [
//         { field: '', headerName: 'Chart', width: 100, renderCell: renderOpenDialogButton},
//         { field: 'pair', headerName: 'Pair', width: 150 },
//         { field: 'latestFundingFeeRate', headerName: 'Latest Funding Fee', width: 150, valueFormatter:fundingFeeRateFormatter },
//         { field: 'apr7', headerName: '7D APR', width: 120, valueFormatter:percentFormatter },
//         { field: 'apr30', headerName: '30D APR', width: 120, valueFormatter:percentFormatter },
//         { field: 'apr180', headerName: '180D APR', width: 120, valueFormatter:percentFormatter },
//     ];
    
//     const diff = dayjs(end).diff(since, 'month');
//     for(let i = 0; i <= diff; i++){
//         let month = dayjs(since).date(1).add(i, 'month');
//         columns.push({ field: 'roi_m'+i, headerName: month.format('MMM YYYY') + ' ROI', width: 140, valueFormatter: percentFormatter});
//     }

//     const handleClose = () => {
//         setDialog({
//             open: false,
//             pair: null,
//         })
//     }

//     return <>
//         <div style={{height:720, width:'100%'}}>
//             <DataGrid 
//                 rows={data}
//                 columns={columns}
//                 components={{
//                     Toolbar: CustomToolbar,
//                 }}
//                 />
//         </div>
//         <Dialog
//             open={dialog.open}
//             onClose={handleClose}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//             maxWidth='lg'
//             fullWidth={true}
//         >
//             <DialogTitle id="alert-dialog-title">{dialog.pair + ' Funding Rate History'}</DialogTitle>
//             <DialogContent>
//                 <Chart symbol={dialog.pair}/>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={handleClose} color="primary">
//                     Close
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     </>
// }

export default FundingFeeExchange;