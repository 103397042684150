import _, { unset } from 'lodash';
import dayjs from 'dayjs';
import { format } from 'mysql';
import { splitSpotAndFutureAmount, exchangeCrypto } from './CryptoFormulaCalculator';

export default class FundingRateSimulator{
    constructor(param){
        const {
            exchangeId,
            since,
            end,
            leverage,
            totalAmount,
            pair,
            cryptoApi,
            startSpotMarketingPrice,
            startFutureMarketingPrice,
            endSpotMarketingPrice,
            endFutureMarketingPrice,
            spotTradingFee,
            futureTradingFee,
            reserveRate,
            futureAmountPrecision,
        } = param;
        this.exchangeId = exchangeId;
        this.since = since;
        this.end = end;
        this.leverage = leverage;
        this.totalAmount = totalAmount;
        this.pair = pair;
        this.startSpotMarketingPrice = startSpotMarketingPrice;
        this.startFutureMarketingPrice = startFutureMarketingPrice;
        this.endSpotMarketingPrice = endSpotMarketingPrice;
        this.endFutureMarketingPrice = endFutureMarketingPrice;
        this.spotTradingFee = spotTradingFee;
        this.futureTradingFee = futureTradingFee;
        this.reserveRate = reserveRate;
        this.futureAmountPrecision = futureAmountPrecision;
        this.cryptoApi = cryptoApi;
    }

    static init(param){
        return new FundingRateSimulator(param);
    }

    async fetchPerformanceSimulateParam(){
        let result = {
            exchange: {
                id: this.exchangeId
            },
            pair: this.pair,
            totalAmount: this.totalAmount,
            since: this.since,
            end: this.end,
            endSpotMarketingPrice: this.endSpotMarketingPrice,
            endFutureMarketingPrice: this.endFutureMarketingPrice,
            spotTradingFee: this.spotTradingFee,
            futureTradingFee: this.futureTradingFee,
            fundingRateHistory:[],
            tradingHistory: [],
        };

        let totalAmount = this.totalAmount;
        let leverage = this.leverage;
        let time = this.since;
        let quoteSpot = this.startSpotMarketingPrice;
        let quoteFuture = this.startFutureMarketingPrice;
        let reserveRate = this.reserveRate;
        let futureAmountPrecision = this.futureAmountPrecision;
        let fundingRateHistory = await this.getFundingRateHistoryAndSnapshotRate(this.pair, dayjs(time).subtract(30, 'day').format('YYYY-MM-DD'), this.end, {hasSpot: true});
        result.fundingRateHistory = fundingRateHistory;
        let tradingItem = splitSpotAndFutureAmount({
            totalAmount,
            leverage,
            spotMarketPrice: quoteSpot,
            futureMarketPrice: quoteFuture,
            reserveRate,
            futureAmountPrecision, 
        });
        tradingItem.time = dayjs(time).valueOf();
        result.tradingHistory.push(tradingItem);

        return result;
    }

    /**
     * For /src/pages/FundingFeeExchange.js use to build the table
     */
    async fetchPerformanceSimulateTableItemParam(){
        let result = {
            exchange: {
                id: this.exchangeId
            },
            pair: this.pair,
            since: this.since,
            end: this.end,
            fundingRateHistory:[],
            tradingHistory: [],
        };
        let fundingRateHistory = await this.getFundingRateHistoryAndSnapshotRate(this.pair, this.since, this.end);
        result.fundingRateHistory = fundingRateHistory;
        return result;
    }

    async splitSpotAndFutureAmountPerMonth({
        amount,
        leverage = 1,
        reserveRate = 0,
        futureAmountPrecision = 3, 
    }){
        const since = this.since;
        const end = this.end;
        const diff = dayjs(end).diff(since, 'month');
        const symbol = this.pair;

        const apiParam = {
            symbol,
            since: dayjs(since).date(1).format(),
            end,
            ISO8601Duration: 'P1M',
        }
        let [{data: spotSnapshotFull}, {data: futureSnapshotFull}] = await Promise.all([
            this.cryptoApi.getSnapshotBySymbol({...apiParam, future: true,}),
            this.cryptoApi.getSnapshotBySymbol({...apiParam, future: false,}),
        ])

        let result = [];
        for(let i = 0; i <= diff; i++){
            let firstDate = dayjs(since).date(1).add(i, 'month');
            let spotSnapshot = _.find(spotSnapshotFull, ['time', firstDate.valueOf()]);
            let futureSnapshot = _.find(futureSnapshotFull, ['time', firstDate.valueOf()]);
            let spotPrice = (spotSnapshot && spotSnapshot['c']) ? spotSnapshot['c'] : null;
            let futurePrice = (futureSnapshot && futureSnapshot['c']) ? futureSnapshot['c'] : null;
            let splitResult = splitSpotAndFutureAmount({
                totalAmount: amount,
                leverage,
                spotMarketPrice: spotPrice,
                futureMarketPrice: futurePrice,
                reserveRate,
                futureAmountPrecision,
            });
            let {totalAmount, futureAmount, remaining} = splitResult;
            result.push({time: firstDate.valueOf(), timeDisplay: firstDate.format(), totalAmount, futureAmount, remaining, debug: splitResult});
        }
        return result;
    }

    async getFundingRateHistoryAndSnapshotRate(symbol, since, end, { hasSpot = false } = {}){
        const priceSince = this.since;
        const priceEnd = this.end;
        let history = await this.cryptoApi.getFundingRateHistory(this.pair, since, end, {
            withSnapshotFuture: true,
        });
        const keyDateFormat = 'YYYYMMDDHH';
        let snapshotDict = {};
        let snapshotSpotDict = {};

        // let snapshot = await this.cryptoApi.getSnapshotBySymbol({
        //     symbol,
        //     since: priceSince,
        //     end: priceEnd,
        //     ISO8601Duration: 'PT8H',
        //     future: true,
        // });
        // snapshotDict = _.keyBy(snapshot.data, (v) => {
        //     return dayjs(v['time']).format(keyDateFormat);
        // });
        if(hasSpot){
            let snapshotSpot = await this.cryptoApi.getSnapshotBySymbol({
                symbol,
                since: priceSince,
                end: priceEnd,
                ISO8601Duration: 'PT8H',
                future: false,
            });
            snapshotSpotDict = _.keyBy(snapshotSpot.data, (v) => {
                return dayjs(v['time']).format(keyDateFormat);
            });    
        }
        history = _(history.data).forEach(v => {
            v['fundingTime'] -= v['fundingTime'] % 1000;
            v['time'] = v['fundingTime'];
            let key = dayjs(v['time']).format(keyDateFormat);
            v['futureMarketingPrice'] = v['c'];
            // if (snapshotDict[key] && snapshotDict[key]['c'] > 0){
            //     v.futureMarketingPrice = snapshotDict[key]['c'];
            // }
            if (snapshotSpotDict[key] && snapshotSpotDict[key]['c'] > 0){
                v.spotMarketingPrice = snapshotSpotDict[key]['c'];
            }
        });

        return history;
    }

    async getLatestSnapshotMarketingPriceByTime(time, future){
        let symbol = this.pair;
        let since = dayjs(time).subtract(10, 'minute').format();
        let end = dayjs(time).format();
        let { data } = await this.cryptoApi.getSnapshotBySymbol({
            symbol,
            since,
            end,
            future,
        });
        if ( !data ) {
            return null;
        }

        let latest = _(data).orderBy(['time'], ['asc']).head();
        return (latest)?latest['c']:null;
    }

    async fetchSpotTradingFee(){
        let symbol = this.pair;
        return {
            info: { maker: '0.0010', symbol, taker: 0.001 },
            symbol,
            maker: 0.001,
            taker: 0.001,
          }
    }

    async fetchFutureTradingFee(){
        let symbol = this.pair;
        return {
            info: { maker: '0.0002', symbol, taker: 0.0004 },
            symbol,
            maker: 0.0002,
            taker: 0.0004,
          }
    }
}