/**
 * Old version of crypto simulator
 * New simulator in /src/pages/ROISimulator
 */
import iddyCryptoApi from '../api/iddyCryptoApi'
import FundingRatePerformance from '../utilities/FundingRatePerformance'
import FundingRateSimulator from '../utilities/FundingRateSimulator'

import {splitSpotAndFutureAmount} from '../utilities/CryptoFormulaCalculator';
import { useEffect, useRef, useState } from 'react';
import { FormControl, Input, InputLabel, TextField, Grid, Card, CardContent, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from "react-hook-form";
import _ from 'lodash';



function TableView({ performanceParam })
{
    const [stat, setStat] = useState(null);

    function compute(performanceParam){
        const performance = FundingRatePerformance.init(performanceParam);
        let si7 = performance.calculateSinceAPR(7);
        let si30 = performance.calculateSinceAPR(30);
        let en7 = performance.calculateEndAPR(7);
        let en30 = performance.calculateEndAPR(30);
        let history = performance.calculateHistoryAPR();
        let spotFutureDiff = performance.calculateSpotFutureDiff();
        let fundingRateEarned = performance.calculateFundingRateEarned();
        let handlingFee = performance.calculateHandlingFee();
        
        console.log('Start APR: startDate - ', performance.since, ', endDate - ', performance.end );
        console.log('Start Date 7D APR ', si7.value, si7);
        console.log('Start Date 30D APR ', si30.value, si30);
        console.log('End Date 7D APR ', en7.value, en7);
        console.log('End Date 30D APR ', en30.value, en30);
        console.log('History APR ', history.value, history);
        console.log('Spot Future Diff: ', spotFutureDiff);
        console.log('Funding Rate Earn: ', fundingRateEarned);
        console.log('HandlingFee: ', handlingFee);

        let newStat = {};
        newStat.startADR7D = si7.value;
        newStat.startADR30D = si30.value;
        newStat.endADR7D = en7.value;
        newStat.endADR30D = en30.value;
        newStat.endADRHistory = history.value;
        newStat.spotFutureDiff = spotFutureDiff;
        newStat.fundingRateEarned = fundingRateEarned;
        newStat.handlingFee = handlingFee;
        newStat.firstTradingHistory = _.head(performance.tradingHistory);
        newStat.totalOutcome = handlingFee + spotFutureDiff + fundingRateEarned;
        newStat.earningYield = performance.calculateEarningYield(newStat.totalOutcome);
                
        setStat(newStat);
    }

    useEffect(() => {
        compute(performanceParam)
    }, [performanceParam]);

    let trade = {};
    if(stat){
        console.log('First Trading history', stat.firstTradingHistory);
        trade = stat.firstTradingHistory;
    }

    if(!stat){
        return null;
    }

    return (
        <>
            <Card>
                <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Total Amount</Typography>
                        <Typography variant="body2" component="p">{trade.totalAmount}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Remaining</Typography>
                        <Typography variant="body2" component="p">{trade.remaining}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Spot Allocation</Typography>
                        <Typography variant="body2" component="p">{trade.spotAllocation}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Future Allocation</Typography>
                        <Typography variant="body2" component="p">{trade.futureAllocation}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Spot Transfer</Typography>
                        <Typography variant="body2" component="p">{trade.spotTransfer}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Future Transfer</Typography>
                        <Typography variant="body2" component="p">{trade.futureTransfer}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Spot Utilized</Typography>
                        <Typography variant="body2" component="p">{trade.spotUtilized}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Future Utilized</Typography>
                        <Typography variant="body2" component="p">{trade.futureUtilized}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Spot Amount</Typography>
                        <Typography variant="body2" component="p">{trade.spotAmount}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Future Amount</Typography>
                        <Typography variant="body2" component="p">{trade.futureAmount}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Spot MarketPrice</Typography>
                        <Typography variant="body2" component="p">{trade.spotMarketPrice}</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Future MarketPrice</Typography>
                        <Typography variant="body2" component="p">{trade.futureMarketPrice}</Typography>
                    </Grid>
                </Grid>
                </CardContent>
            </Card>

            <Card>
                <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">From Date 7D APR</Typography>
                        <Typography variant="body2" component="p">{Math.round(stat.startADR7D*100*1000)/1000}%</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">From Date 30D APR</Typography>
                        <Typography variant="body2" component="p">{Math.round(stat.startADR30D*100*1000)/1000}%</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">To Date 7D APR</Typography>
                        <Typography variant="body2" component="p">{Math.round(stat.endADR7D*100*1000)/1000}%</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">To Date 30D APR</Typography>
                        <Typography variant="body2" component="p">{Math.round(stat.endADR30D*100*1000)/1000}%</Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <Typography variant="h5" component="h2">Range APR</Typography>
                        <Typography variant="body2" component="p">{Math.round(stat.endADRHistory*100*1000)/1000}%</Typography>
                    </Grid>
                </Grid>
                </CardContent>
            </Card>

            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={3} xs={6}>
                            <TextField disabled defaultValue={stat.handlingFee} label="Handling Fee" />
                            +
                            <TextField disabled defaultValue={stat.spotFutureDiff} label="Spot Future Diff" />
                            +
                            <TextField disabled defaultValue={stat.fundingRateEarned} label="Funding Rate Earned" />
                            =
                            <TextField disabled defaultValue={stat.totalOutcome} label="Total Outcome" />
                            <TextField disabled defaultValue={Math.round(stat.earningYield*100*1000)/1000 + '%'} label="Earning Yield" />
                        </Grid>                                
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}



function Simulator(){

    const [data, setData] = useState({
        exchangeId: 'binance',
        since: '2020-07-15',
        end: '2020-10-15',
        pair: 'BTC/USDT',
        totalAmount: 1000,
        leverage: 3,
        reserveRate: 0.02,
        futureAmountPrecision: 3,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [spot, setSpot] = useState(null);
    
    const performanceParam = useRef(null);
    
    
    
    // let main = async function() {
    //     let newStat = {};
    //     // TODO: create context to store the api object
    //     let simulatorParam = {...data};
    //     simulatorParam.cryptoApi = new iddyCryptoApi({})
    
    //     const simulator = FundingRateSimulator.init(simulatorParam);
    //     console.log('State Spot: ', spot);

    //     if(
    //         !spot ||
    //         !spot.startSpotMarketingPrice || 
    //         !spot.startFutureMarketingPrice ||
    //         !spot.endSpotMarketingPrice ||
    //         !spot.endFutureMarketingPrice
    //     ){
    //         let newSpot = {...spot};
    //         if(!spot || !spot.startSpotMarketingPrice){
    //             newSpot.startSpotMarketingPrice = await simulator.getLatestSnapshotMarketingPriceByTime(simulator.since, true);
    //         }

    //         if(!spot || !spot.startFutureMarketingPrice){
    //             newSpot.startFutureMarketingPrice = await simulator.getLatestSnapshotMarketingPriceByTime(simulator.since, false);
    //         }

    //         if(!spot || !spot.endSpotMarketingPrice){
    //             newSpot.endSpotMarketingPrice = await simulator.getLatestSnapshotMarketingPriceByTime(simulator.end, true);
    //         }
    //         if(!spot || !spot.endFutureMarketingPrice){
    //             newSpot.endFutureMarketingPrice = await simulator.getLatestSnapshotMarketingPriceByTime(simulator.end, false);        
    //         }
    //         console.log('NewSpot:', newSpot);
    //         setSpot(newSpot);
    //         return;
    //     }
    //     simulator.startSpotMarketingPrice = spot.startSpotMarketingPrice;
    //     simulator.startFutureMarketingPrice = spot.startFutureMarketingPrice;
    //     simulator.endSpotMarketingPrice = spot.endSpotMarketingPrice;
    //     simulator.endFutureMarketingPrice = spot.endFutureMarketingPrice;
    //     console.log('Simulator: ', simulator);
    //     simulator.spotTradingFee = await simulator.fetchSpotTradingFee();
    //     simulator.futureTradingFee = await simulator.fetchFutureTradingFee();

    //     let result = await simulator.fetchPerformanceSimulateParam();
    //     console.log(simulator);
    //     console.log('performanceParam: ', result);
    //     performanceParam.current = result
    // }

    // useEffect(() => {
    //     main();
    // }, [data, spot]);


    async function fetch(formData){
        setIsLoading(true);

        // TODO: create context to store the api object
        let simulatorParam = {...formData};
        simulatorParam.cryptoApi = new iddyCryptoApi({})
    
        const simulator = FundingRateSimulator.init(simulatorParam);

        let newSpot = {};
        // if(!spot || !spot.startSpotMarketingPrice){
            newSpot.startSpotMarketingPrice = await simulator.getLatestSnapshotMarketingPriceByTime(simulator.since, true);
        // }

        // if(!spot || !spot.startFutureMarketingPrice){
            newSpot.startFutureMarketingPrice = await simulator.getLatestSnapshotMarketingPriceByTime(simulator.since, false);
        // }

        // if(!spot || !spot.endSpotMarketingPrice){
            newSpot.endSpotMarketingPrice = await simulator.getLatestSnapshotMarketingPriceByTime(simulator.end, true);
        // }
        // if(!spot || !spot.endFutureMarketingPrice){
            newSpot.endFutureMarketingPrice = await simulator.getLatestSnapshotMarketingPriceByTime(simulator.end, false);        
        // }
        console.log('NewSpot:', newSpot);
        setSpot(newSpot);

        setValue('startSpot', newSpot.startFutureMarketingPrice)
        setValue('startFuture', newSpot.startFutureMarketingPrice)
        setValue('endSpot', newSpot.endSpotMarketingPrice)
        setValue('endFuture', newSpot.endFutureMarketingPrice)

        simulator.startSpotMarketingPrice = newSpot.startSpotMarketingPrice;
        simulator.startFutureMarketingPrice = newSpot.startFutureMarketingPrice;
        simulator.endSpotMarketingPrice = newSpot.endSpotMarketingPrice;
        simulator.endFutureMarketingPrice = newSpot.endFutureMarketingPrice;
        console.log('Simulator: ', simulator);
        simulator.spotTradingFee = await simulator.fetchSpotTradingFee();
        simulator.futureTradingFee = await simulator.fetchFutureTradingFee();

        let result = await simulator.fetchPerformanceSimulateParam();
        console.log(simulator);
        console.log('performanceParam: ', result);
        performanceParam.current = result

        setIsLoading(false);

        // // TODO allow change value by user
        // setSpot(null);
        // setStat(null);
        // if(startSpot || startFuture || endSpot || endFuture){
        //     // setSpot({
        //     //     startSpotMarketingPrice: _.toNumber(startSpot),
        //     //     startFutureMarketingPrice: _.toNumber(startFuture),
        //     //     endSpotMarketingPrice: _.toNumber(endSpot),
        //     //     endFutureMarketingPrice: _.toNumber(endFuture),
        //     // });
        // }
    }

    useEffect(() => {
        fetch(data);
    }, [])

    const { control, handleSubmit, setValue } = useForm();
    const onSubmit = async formData => {

        // setStat(null);
        const {
            since,
            end,
            pair,
            totalAmount,
            leverage,
            reserveRate,
            futureAmountPrecision,
            // startSpot,
            // startFuture,
            // endSpot,
            // endFuture,
        } = formData;
        
        let newData = {since, end, pair, totalAmount: _.toNumber(totalAmount), leverage: _.toNumber(leverage), reserveRate: _.toNumber(reserveRate), futureAmountPrecision: _.toNumber(futureAmountPrecision), exchangeId: 'binance',};
        setData(newData);

        fetch(newData);

    };
    const useStyles = makeStyles((theme) => ({
        root: {
          '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
          },
        },
      }));
    const classes = useStyles();


    return <>
        <div>
            <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <Controller as={TextField} name="since" label="From Date" control={control} defaultValue={data.since} />
            <Controller as={TextField} name="end" label="To Date" control={control} defaultValue={data.end} />
            <Controller as={TextField} name="pair" label="Pair" control={control} defaultValue={data.pair} />
            <Controller as={TextField} name="totalAmount" label="Total Amount" control={control} defaultValue={data.totalAmount} />
            <Controller as={TextField} name="leverage" label="Leverage" control={control} defaultValue={data.leverage} />
            <Controller as={TextField} name="reserveRate" label="Reserve Rate" control={control} defaultValue={data.reserveRate} />
            <Controller as={TextField} name="futureAmountPrecision" label="Future Max Decimal Point" control={control} defaultValue={data.futureAmountPrecision} />
            <br/>
            {spot !== null &&
            <>
                <Controller as={TextField} name="startSpot" label="From Date Spot Marketing Price" control={control} defaultValue={spot.startSpotMarketingPrice} />
                <Controller as={TextField} name="startFuture" label="From Date Future Marketing Price" control={control} defaultValue={spot.startFutureMarketingPrice} />
                <Controller as={TextField} name="endSpot" label="To Date Spot Marketing Price" control={control} defaultValue={spot.endSpotMarketingPrice} />
                <Controller as={TextField} name="endFuture" label="To Date Future Marketing Price" control={control} defaultValue={spot.endFutureMarketingPrice} />
                <br/>
            </>
            }
            <input type="submit" />
            </form>
        </div>
        <div>
            {isLoading && <CircularProgress />}
            {
                !isLoading &&
                performanceParam.current !== null && 
                <TableView performanceParam={performanceParam.current} />
            }
        </div>
    </>;
}

export default Simulator;