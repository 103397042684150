import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Slider from '@material-ui/core/Slider';

import dayjs from 'dayjs'
import axios from 'axios'
import _ from 'lodash'



function useDebouncedCallback(cb){
    return _.debounce(cb, 300);
}


export default function StatsTable({ symbol })
{
    const [sinceDay, setSinceDay] = useState(0)
    const [endDay, setEndDay] = useState(0)
    const [data, setData] = useState(null)

    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [durationDays, setDurationDays] = useState(0);

    async function fetchData(params = {}){

        let response = await axios.get('/funding_rate_history_stats', {
            params: params
        });
        console.log("response = ", response)

        const responseData = response.data;
        setMinDate(responseData.datasetFrom);
        setMaxDate(responseData.datasetTo);
        let diff = dayjs(responseData.datasetTo).diff(dayjs(responseData.datasetFrom), "days");
        // console.log("diff = ", Math.abs(diff))
        setDurationDays(Math.abs(diff));

        setData(responseData);
    }

    useEffect(() => {
        let params = {
            symbol: symbol,
        };
        if(sinceDay != 0){
            params.since = dayjs(minDate).add(sinceDay, 'day').format("YYYY-MM-DD");
        }
        if(endDay != 0){
            params.end = dayjs(minDate).add(endDay, 'day').format("YYYY-MM-DD");
        }
        fetchData(params);
    }, [sinceDay, endDay]);


    const sliderLabel = useCallback((value) => {
        return minDate? dayjs(minDate).add(value, 'day').format("DD MMM") : "";
    }, [minDate]);

    
    const handleChange = useDebouncedCallback(useCallback((event, newValue) => {
        // console.log(newValue)
        const [newSince, newEnd] = newValue;
        setSinceDay(newSince);
        setEndDay(newEnd);
    }, []));


    if(!data){
        return null;
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                    <Typography variant="h5" component="h2">From Date</Typography>
                    <Typography variant="body2" component="p">{data.from}</Typography>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Typography variant="h5" component="h2">To Date</Typography>
                    <Typography variant="body2" component="p">{data.to}</Typography>
                </Grid>
            </Grid>
            <div>
                <Slider
                    // marks
                    getAriaValueText={sliderLabel}
                    valueLabelFormat={sliderLabel}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    onChange={handleChange}

                    defaultValue={[0, durationDays]}
                    step={1}
                    min={0}
                    max={durationDays}
                />
            </div>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <Card>
                        <CardContent>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6}>
                                <Typography variant="h5" component="h2">Total datapoints</Typography>
                                <Typography variant="body2" component="p">{data.count}</Typography>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Typography variant="h5" component="h2">Days</Typography>
                                <Typography variant="body2" component="p">{data.days_count}</Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="h5" component="h2">Profitable count</Typography>
                                <Typography variant="body2" component="p">{data.positiveCount}</Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography variant="h5" component="h2">Negative count</Typography>
                                <Typography variant="body2" component="p">{data.negativeCount}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h5" component="h2">Positive%</Typography>
                                <Typography variant="body2" component="p">{Math.round(data.positivePercentage*100)/100}%</Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                </Grid>


                <Grid item md={6}>
                    <Card>
                        <CardContent>
                        <Grid container spacing={3}>
                            <Grid item md={3} xs={6}>
                                <Typography variant="h5" component="h2">Min rate</Typography>
                                <Typography variant="body2" component="p">{Math.round(data.minRate*100*1000)/1000}%</Typography>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Typography variant="h5" component="h2">Max rate</Typography>
                                <Typography variant="body2" component="p">{Math.round(data.maxRate*100*1000)/1000}%</Typography>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Typography variant="h5" component="h2">Avg rate</Typography>
                                <Typography variant="body2" component="p">{Math.round(data.averageRate*100*1000)/1000}%</Typography>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Typography variant="h5" component="h2">Cumulative Return</Typography>
                                <Typography variant="body2" component="p">{Math.round(data.accumulateRate*100*1000)/1000}%</Typography>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Typography variant="h5" component="h2">Start rate</Typography>
                                <Typography variant="body2" component="p">{Math.round(data.startRate*100*1000)/1000}%</Typography>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Typography variant="h5" component="h2">Projected return</Typography>
                                <Typography variant="body2" component="p">{Math.round(data.projectedReturn*100*1000)/1000}%</Typography>
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <Typography variant="h5" component="h2">Projected Yearly</Typography>
                                <Typography variant="body2" component="p">{Math.round(data.projectedYearlyReturn*100*1000)/1000}%</Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                

                

                

            </Grid>
        </div>
    )
}