import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import _ from 'lodash';
import {calcTradingFee, MAKER_OR_TAKER} from './CryptoFormulaCalculator';

dayjs.extend(utc);
dayjs.extend(timezone);
export default class FundingRatePerformance{
    
    constructor(param){
        const {
            exchange,
            pair,
            totalAmount,
            since,
            end,
            endSpotMarketingPrice,
            endFutureMarketingPrice,
            fundingRateHistory,
            tradingHistory, 
            spotTradingFee, 
            futureTradingFee, 
        } = param;
        this.exchange = exchange;
        this.pair = pair;
        this.totalAmount = totalAmount;
        this.since = since;
        this.end = end;
        this.endSpotMarketingPrice = endSpotMarketingPrice;
        this.endFutureMarketingPrice = endFutureMarketingPrice;
        this.fundingRateHistory = fundingRateHistory;
        this.tradingHistory = tradingHistory;
        this.spotTradingFee = spotTradingFee;
        this.futureTradingFee = futureTradingFee;
        this.debug = {};
    }

    static init(param){
        return new FundingRatePerformance(param);
    }



    calculateSinceAPR(period = 7){
        let time = this.since;
        let startDate = dayjs(time).subtract(period , 'day').valueOf();
        let endDate = dayjs(time).valueOf();
        return this.calculateAPR(startDate, endDate);
    }

    calculateEndAPR(period = 7){
        let time = this.end;
        let startDate = dayjs(time).subtract(period , 'day').valueOf();
        let endDate = dayjs(time).valueOf();
        return this.calculateAPR(startDate, endDate);        
    }
    
    calculateHistoryAPR(){
        let startDate = dayjs(this.since).valueOf();
        let endDate = dayjs(this.end).valueOf();
        return this.calculateAPR(startDate, endDate);        
    }

    calculateAPR(startDate, endDate){
        let rateHistories = this.fundingRateHistory;
        let start = dayjs(startDate).valueOf();
        let end = dayjs(endDate).valueOf();
        let periodRateHistory = _.filter(rateHistories, ({time}) => {
            return time >= start && time <= end;
        });
        let value = _.meanBy(periodRateHistory, 'fundingRate') * 3 * 365;
        return { 
            value,
            match: periodRateHistory
        };
    }

    calculateSpotFutureDiff(){
        const {
            endSpotMarketingPrice,
            endFutureMarketingPrice,
        } = this;
        const {
            spotMarketPrice: startSpotMarketPrice,
            spotAmount,
            futureMarketPrice: startFutureMarketPrice,
            futureAmount,
        } = _.last(this.tradingHistory);
        let earn = 0;

        // handle spot earn
        earn += (endSpotMarketingPrice - startSpotMarketPrice) * spotAmount;

        // handle future earn
        earn -= (endFutureMarketingPrice - startFutureMarketPrice) * futureAmount;

        return earn;
    }

    calculateFundingRateEarned(){
        let data = this.calculateFundingRateEarnedPerHistory();
        return _.sumBy(data, 'earn');
    }

    calculateFundingRateEarnedPerHistory(){
        let rateHistories = this.fundingRateHistory;
        let start = dayjs(this.since).valueOf();
        let end = dayjs(this.end).valueOf();
        let tradingHistory = this.tradingHistory;
        let periodRateHistory = _.filter(rateHistories, ({time}) => {
            return time >= start && time <= end;
        });
        const tradingGenerator = function* (rows, key){
            let input;
            for(var i = 0; i < rows.length; i++){
                let loop = true;
                while(loop){
                    loop = true;
                    input = yield rows[i];
                    if ( i !== rows.length - 1 ) {
                        let to = rows[i + 1][key];
                        if(input >= to){
                            loop = false;
                        }
                    }
                };
            }
        }

        const g1 = tradingGenerator(tradingHistory, 'time');
        periodRateHistory.forEach( v => {
            let {time, fundingRate, futureMarketingPrice} = v;
            let trade = g1.next(time).value;
            let futureAmount = trade.futureAmount;
            v.earn = futureAmount * futureMarketingPrice * fundingRate;
        });
        return periodRateHistory;
    }

    calculateHandlingFee(){
        let handingFee = 0;
        let tradingHistory = this.tradingHistory;
        let spotTradingFee = this.spotTradingFee;
        let futureTradingFee = this.futureTradingFee;
        let feeTrading = tradingHistory.map( v => {
            let {time, futureTradeAmount, futureMarketPrice, spotTradeAmount, spotMarketPrice } = v;
            let tradingFee = 0;
            tradingFee += calcTradingFee(spotTradeAmount * spotMarketPrice, MAKER_OR_TAKER.TAKER, spotTradingFee);
            tradingFee += calcTradingFee(futureTradeAmount * futureMarketPrice, MAKER_OR_TAKER.TAKER, futureTradingFee);
            console.log('Spot Time: ', dayjs(time).format(), spotTradeAmount, spotMarketPrice, MAKER_OR_TAKER.TAKER, spotTradingFee, calcTradingFee(spotTradeAmount * spotMarketPrice, MAKER_OR_TAKER.TAKER, spotTradingFee));
            console.log('Future Time: ', dayjs(time).format(), futureTradeAmount, futureMarketPrice, MAKER_OR_TAKER.TAKER, futureTradingFee, calcTradingFee(futureTradeAmount * futureMarketPrice, MAKER_OR_TAKER.TAKER, futureTradingFee));
            return {
                time,
                spotTradeAmount,
                futureTradeAmount,
                tradingFee,
            }
        });
        handingFee += _.sumBy(feeTrading, 'tradingFee');
        // calculate spot and future return to rebase 
        const {
            endSpotMarketingPrice,
            endFutureMarketingPrice,
        } = this;
        const {
            spotAmount,
            futureAmount,
        } = _.last(this.tradingHistory);

        // spot fee
        handingFee += calcTradingFee(endSpotMarketingPrice * spotAmount, MAKER_OR_TAKER.TAKER, spotTradingFee);
        console.log('Final Spot Time: ', endSpotMarketingPrice, spotAmount, MAKER_OR_TAKER.TAKER, spotTradingFee, calcTradingFee(endSpotMarketingPrice * spotAmount, MAKER_OR_TAKER.TAKER, spotTradingFee));

        // future need to confirm
        handingFee += calcTradingFee(endFutureMarketingPrice * futureAmount, MAKER_OR_TAKER.TAKER, futureTradingFee);
        console.log('Final Future Time: ', endFutureMarketingPrice, futureAmount, MAKER_OR_TAKER.TAKER, futureTradingFee, calcTradingFee(endFutureMarketingPrice * futureAmount, MAKER_OR_TAKER.TAKER, futureTradingFee));

        return handingFee * -1;
    }

    calculateEarningYield(outCome){
        return outCome / this.totalAmount;
    }

    calculateROIChartData({
        groupBy 
    } = {}){
        const groupDateFormat = (groupBy === 'MONTH')?'MM/YY':'DD/MM/YY';
        let rateHistories = this.fundingRateHistory;
        let since = dayjs(this.since).tz("Asia/Hong_Kong").valueOf();
        let end = dayjs(this.end).tz("Asia/Hong_Kong").valueOf();
        let periodRateHistory = _.filter(rateHistories, ({time}) => {
            return time >= since && time <= end;
        });
        let groupResult = _.groupBy(periodRateHistory, v => {
            let d = dayjs(v.time).tz("Asia/Hong_Kong").format(groupDateFormat);
            return d;
        });

        let data = _.toPairs(groupResult).map( ([key, v]) => {
            let item = {};
            let arr = _.sortBy(v, 'fundingTime');
            item.label = key;
            item.roi = _.sumBy(arr, 'earn');
            item.spotPrice = _.head(arr).spotMarketingPrice;
            item.futurePrice = _.head(arr).futureMarketingPrice;
            return item;
        });
        return {
            data,
            labels: _.map(data, 'label'),
            datasets: {
                roi: _.map(data, 'roi'),
                spotPrice: _.map(data, 'spotPrice'),
                futurePrice: _.map(data, 'futurePrice'),
            }
        }
    }

    calculateFundingRateROIByMonth(positionData){
        const rateHistory = this.fundingRateHistory;
        const monthlyHistory = _.groupBy(rateHistory, v => {
                    return dayjs(v.time).format('YYYY-MM')
                })
        const monthlyPosition = _.keyBy(positionData, v => {
            return dayjs(v.time).format('YYYY-MM')
        });

        let result = [];
        _.forIn(monthlyHistory, (value, key) => {
            let position = monthlyPosition[key] ?? {};
            const {time, futureAmount, totalAmount} = position;
            let feePerUnit = _.sumBy(value, history => {
                return history['futureMarketingPrice'] * history['fundingRate']
            })
            let roi = futureAmount * feePerUnit / totalAmount;
            result.push({
                time,
                roi,
            });
        })
        return result;
    }

    convertToPortfolioTransactions(){
        let trans = [];
        
        trans.push({
            action: 'add_cash',
            tran_date: dayjs.tz(this.since, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_total: this.totalAmount,
        })
        
        let firstTrading = _.head(this.tradingHistory);
        trans.push({
            action: 'buy_sell',
            market: 'spot',
            exchange: 'binance',
            symbol: this.pair,
            tran_date: dayjs.tz(this.since, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_price: firstTrading.spotMarketPrice,
            tran_qty: firstTrading.spotAmount,
            tran_fee: 0,
            tran_total: firstTrading.spotMarketPrice * firstTrading.spotAmount,
        })
        trans.push({
            action: 'buy_sell',
            market: 'future',
            exchange: 'binance',
            symbol: this.pair,
            tran_date: dayjs.tz(this.since, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_price: firstTrading.futureMarketPrice,
            tran_qty: -1 * firstTrading.futureAmount,
            tran_fee: 0,
            tran_total: -1 * firstTrading.futureMarketPrice * firstTrading.futureAmount,
        });

        // fee
        trans.push({
            action: "handling_fee",
            tran_date: dayjs.tz(this.since, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_total: -1 * calcTradingFee(firstTrading.spotAmount * firstTrading.spotMarketPrice, MAKER_OR_TAKER.TAKER, this.spotTradingFee),
            market: "spot",
            exchange: 'binance',
            symbol: this.pair,
        })
        trans.push({
            action: "handling_fee",
            tran_date: dayjs.tz(this.since, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_total: -1 * calcTradingFee(firstTrading.futureAmount * firstTrading.futureMarketPrice, MAKER_OR_TAKER.TAKER, this.futureTradingFee),
            market: "future",
            exchange: 'binance',
            symbol: this.pair,
        })

        let rateHistory = this.calculateFundingRateEarnedPerHistory();
        _.forEach(rateHistory, ({earn, time}) => {
            trans.push({
                action: "funding_fee",
                market: "future",
                exchange: 'binance',
                symbol: this.pair,
                tran_date: dayjs.tz(time, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
                tran_total: earn,
            })
        })

        trans.push({
            action: 'buy_sell',
            market: 'spot',
            exchange: 'binance',
            symbol: this.pair,
            tran_date: dayjs.tz(this.end, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_price: this.endSpotMarketingPrice,
            tran_qty: -1 * firstTrading.spotAmount,
            tran_fee: 0,
            tran_total: -1 * this.endSpotMarketingPrice * firstTrading.spotAmount,
        })
        trans.push({
            action: 'buy_sell',
            market: 'future',
            exchange: 'binance',
            symbol: this.pair,
            tran_date: dayjs.tz(this.end, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_price: this.endFutureMarketingPrice,
            tran_qty: firstTrading.futureAmount,
            tran_fee: 0,
            tran_total: this.endFutureMarketingPrice * firstTrading.futureAmount,
        });

        // fee
        trans.push({
            action: "handling_fee",
            tran_date: dayjs.tz(this.end, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_total: -1 * calcTradingFee(firstTrading.spotAmount * this.endSpotMarketingPrice, MAKER_OR_TAKER.TAKER, this.spotTradingFee),
            market: "spot",
            exchange: 'binance',
            symbol: this.pair,
        })
        trans.push({
            action: "handling_fee",
            tran_date: dayjs.tz(this.end, "Asia/Hong_Kong").format('YYYY-MM-DDTHH:mm:ssZ'),
            tran_total: -1 * calcTradingFee(firstTrading.futureAmount * this.endFutureMarketingPrice, MAKER_OR_TAKER.TAKER, this.futureTradingFee),
            market: "future",
            exchange: 'binance',
            symbol: this.pair,
        })
        

        return trans;
    }
}