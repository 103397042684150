import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';

import dayjs from 'dayjs'
import axios from 'axios'
import _ from 'lodash'

export default function Chart({ symbol, width })
{
    width = width? width : 600

    const chartContainer = useRef(null);

    const chartRef = useRef(null);
    const seriesRef = useRef(null);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {

        if(chartContainer.current == null){
            return null;
        }

        console.log("createChart");
        const chart = createChart(chartContainer.current, {
            width: width,
            height: width*0.7,
            localization: {
                timeFormatter: (timestamp) => dayjs(timestamp).format('YYYY-MM-DD HH:mm'),
                priceFormatter: function(price) { return price.toFixed(4) + "%"; }
            },
        });
        chartRef.current = chart;

        const lineSeries = chart.addLineSeries();
        seriesRef.current = lineSeries;


        return () => {
            if(chartContainer.current != null){
                console.log("chartContainer.current = ", chartContainer.current);
                // chartContainer.current.remove();
                while (chartContainer.current.firstChild) {
                    chartContainer.current.removeChild(chartContainer.current.firstChild)
                }
            }
        }

    }, [chartContainer.current]);


    async function fetchData(){
        let response = await axios.get('/funding_rate_history', {
            params: {
                symbol: symbol
            }
        });

        // console.log("response.data = ", response.data)
        let transformedData = _.map(response.data, (item) => {
            // let dateStr = dayjs(item.fundingTime).format('YYYY-MM-DD HH:mm');
            let rate = item.fundingRate * 100;
            // console.log(`${dateStr} : ${rate}`);
            // console.log("isString = ", _.isString(dateStr));
            return {
                // time: dateStr,
                time: item.fundingTime,
                value: rate,
            }
        });

        // console.log("transformedData = ", transformedData)
        // seriesRef.current.setData(_.cloneDeep(transformedData))
        setChartData(transformedData)
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(seriesRef.current != null){
            seriesRef.current.setData(chartData)
        }

        const chart = chartRef.current;

        chart.applyOptions({
            rightPriceScale: {
                scaleMargins: {
                    top: 0.01,
                    bottom: 0,
                },
            },
        });
        chart.applyOptions({
            timeScale: {
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    // console.log(time, tickMarkType, locale);
                    // const year = LightweightCharts.isBusinessDay(time) ? time.year : new Date(time * 1000).getUTCFullYear();
                    // return String(year);
                    return dayjs(time).format('YYYY-MM-DD');
                },
            }
        });
        chart.timeScale().applyOptions({
            timeVisible: true,
            secondsVisible: true,
        });
        chart.timeScale().resetTimeScale();
        // chart.timeScale().fitContent();

    }, [chartData])


    return (
        <div ref={chartContainer} style={{width: width, margin:'0 auto'}}></div>
    )
}