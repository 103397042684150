import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./styles.scss";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import App from './App';
import DebugPanelApp from './DebugPanelApp';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/debug-panel" component={DebugPanelApp} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
