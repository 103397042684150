import { TextField, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { useForm, Controller, useController } from "react-hook-form";

function HookFormSelect(props){
    const {
        control,
        form,
        name, 
        label, 
        options,
        defaultValue,
        onChange,
        onBlur,
    } = props;

    const {
        field: { ref, ...inputProps },
    } = useController({
        name,
        control,
        rules: { required: true },
        defaultValue,
    });

    const handleOnChange = (e) => {
        inputProps.onChange(e)
        if(typeof onChange === 'function'){
            onChange(e, {
                form
            });
        }
    }
    const handleOnBlur = (e) => {
        inputProps.onBlur(e)
        if(typeof onBlur === 'function'){
            onBlur(e, {
                form
            });
        }
    }
    return <>
            <TextField 
                select
                label={label} 
                labelId={name} 
                variant="outlined" 
                {...inputProps} 
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                inputRef={ref}
                fullWidth>
                {options.map( ({name, value}) =>  
                    <MenuItem key={value} value={value}>
                        {name}
                    </MenuItem> 
                    ) }
            </TextField>
  </>
}

export default HookFormSelect;