import { Box, Typography, Grid, Button } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { cloneElement, useEffect } from 'react';

function HookGridForm(props){
    const {
        groups,
        defaultValues,
        onSubmit = () => {},
    } = props;
    const form = useForm({
        defaultValues,
    });
    const { control, handleSubmit } = form;

    return <form onSubmit={handleSubmit(onSubmit)}>
        {groups.map(group => {
            return <Box>
                <Typography variant="h6">
                    {group.name}
                </Typography>
                <Grid container spacing="3">
                    {group.children.map(component => {
                        let CloneComponent = cloneElement(component, {
                            control: control,
                            form: form,
                        });
                        return <Grid item xs={6} md={3}>
                                {CloneComponent}
                            </Grid>;
                    })}
                </Grid>
            </Box>
        })}
        <Box>
            <Grid container spacing="3">
                <Grid item>
                    <Button type="submit" variant="contained" color="primary" disableElevation>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Box>
    </form>
}

export default HookGridForm;