import React from 'react';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';

// import logo from './logo.svg';
import './App.css';

import Chart from './components/Chart';
import StatsTable from './components/StatsTable';
import Simulator from './components/Simulator';

import { useStyles } from './styles';


import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";


import SFACreateForm from './components/SFACreateForm';
import AdminScreen from './AdminScreen'


function BTCHome(){
  const symbol = "BTC/USDT"

  return (
    <>
      <div style={{padding:20, paddingBottom:50}}>
        {/* <h1>Crypto Panel</h1> */}
        <a target="_blank" href={"https://www.binance.com/en/futures/"+symbol.replace("/", "")}>Binance {symbol}</a>
        <h4>{symbol} Funding Rate History</h4>
        <div style={{marginBottom:15}}>
          <Chart symbol={symbol} />
        </div>
        <StatsTable symbol={symbol} />
      </div>
    </>
  )
}


function ETHHome(){

  const symbol = "ETH/USDT"

  return (
    <>
      <div style={{padding:20, paddingBottom:50}}>
        <a target="_blank" href={"https://www.binance.com/en/futures/"+symbol.replace("/", "")}>Binance {symbol}</a>
        <h4>{symbol} Funding Rate History</h4>
        <div style={{marginBottom:15}}>
          <Chart symbol={symbol} />
        </div>
        <StatsTable symbol={symbol} />
      </div>
    </>
  )
}


function Home(){
  return (
    <>
      <div className="App-header">
        {/* <h1>Crypto Panel</h1> */}
        {/* <a className="App-link" target="_blank" href="https://www.binance.com/en/futures/BTCUSDT">Binance BTCUSDT</a> */}
        <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography variant="h4">BTC/USDT</Typography>
              <Typography variant="subtitle1">Funding Rate History</Typography>
              <div style={{marginBottom:15}}>
                <Chart symbol="BTC/USDT" width={480} />
              </div>
            </Grid>
            <Grid item md={6} xs={12} justify={"center"}>
              <Typography variant="h4">ETH/USDT</Typography>
              <Typography variant="subtitle1">Funding Rate History</Typography>
              <div style={{marginBottom:15}}>
                <Chart symbol="ETH/USDT" width={480} />
              </div> 
            </Grid>
        </Grid>
      </div>
      <div style={{padding:20, paddingBottom:50}}>
        {/* <StatsTable /> */}
      </div>
    </>
  )
}


function App() {
  return (
    <Router>
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" noWrap>
            Crypto strategy Debug Panel
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
          <Link color="inherit" component={Button} to="/">Home</Link>
          <Link color="inherit" component={Button} to="/BTC">BTC</Link>
          <Link color="inherit" component={Button} to="/ETH">ETH</Link>
          <Link color="inherit" component={Button} to="/Simulator">Simulator</Link>
          <Link color="inherit" component={Button} to="/admin">Admin</Link>
          {/* <Link color="inherit" component={Button} to="/SFACreateForm">Create Bot</Link> */}
        </Toolbar>
      </AppBar>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/BTC" component={BTCHome} />
        <Route exact path="/ETH" component={ETHHome} />
        <Route exact path="/Simulator" component={Simulator} />
        <Route exact path="/admin" component={AdminScreen} />
      </Switch>

      <footer>
        <p>Copyright 2021 iDDY Fintech HK Limited</p>
      </footer>
    </div>
    </Router>
  );
}

export default App;
