import React, { useEffect, useMemo, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import ReactJson from 'react-json-view';

import Slider from '@material-ui/core/Slider';

import axios from 'axios'
import _ from 'lodash'
import { DataGrid } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';

import styles from './AdminScreen.module.scss';

const BASE_URL = "http://tradingbot-sfa.app.iddy.asia";

async function startBot(id){
    if(window.confirm('Start Bot (ID:'+id+') ?')){

        let response = await axios({
            method: 'post',
            url: '/api/startBot',
            baseURL: BASE_URL,
            data: {
                strategyId: id,
            }
        });

        console.log("startBot response = ", response)

    }
}

async function stopBot(id){
    if(window.confirm('Stop Bot (ID:'+id+') ?')){

        let response = await axios({
            method: 'post',
            url: '/api/stopBot',
            baseURL: BASE_URL,
            data: {
                strategyId: id,
            }
        });

        console.log("stopBot response = ", response)

    }
}

async function fetchStrategyDebugInfo(strategyId) {
    const { data } = await axios({
        method: 'GET',
        params: {
            'strategy_id': strategyId,
        },
        url: '/strategy/debug-info',
    });
    return data;
}

async function fetchStrategyDetails(strategyId) {
    const { data } = await axios({
        method: 'GET',
        params: {
            'strategy_id': strategyId,
        },
        url: '/strategy/details',
    });
    return data;
}

export function StrategyTable({  })
{
    const [strategyIdFilter, setStrategyIdFilter] = useState('');
    const [strategyDebugInfo, setStrategyDebugInfo] = useState(null);
    const [shouldShowStrategyDebugInfoDialog, setShouldShowStrategyDebugInfoDialog] = useState(false);
    const [strategyDetails, setStrategyDetails] = useState(null);
    const [shouldShowStrategyDetailsDialog, setShouldShowStrategyDetailsDialog] = useState(false);
    const [data, setData] = useState([]);

    const botsColumns = [
        { field: 'id', headerName: 'id' },
        { field: 'user_id', headerName: 'user_id' },
        { field: 'pair', headerName: 'pair' },
        { field: 'type', headerName: 'type', width: 130},
        { field: 'amount', headerName: 'amount' },
        { field: 'leverage', headerName: 'leverage' },
        { field: 'status', headerName: 'status' },
        {
            field: 'Debug',
            headerName: 'Debug',
            renderCell: params => {
                return (
                    <Button variant="outlined" color="primary" onClick={handleDebugButtonClick(params)}>Debug</Button>
                );
            },
        },
        {
            field: 'Details',
            headerName: 'Details',
            renderCell: params => {
                return (
                    <Button variant="outlined" color="primary" onClick={handleDetailsButtonClick(params)}>Details</Button>
                );
            },
        },
    ];


    async function fetchList(strategyId = ''){
        const { data } = await axios({
            method: 'GET',
            url: '/strategy/list',
            params: {
                'strategy_id': strategyId,
            },
        });

        setData(data);
    }

    useEffect(() => {
        fetchList();
    }, []);

    useEffect(async () => {
        await fetchList((strategyIdFilter || '').trim());
    }, [strategyIdFilter])

    function handleDebugButtonClick(params) {
        return async () => {
            await fetchStrategyDebugInfo(params.id)
                .then(res => {
                    res.orders.forEach(order => {
                        res.instructionDetails.forEach(details => {
                            if (order['instruction_detail_id'] === details.id) {
                                details.orders = details.orders || [];
                                details.orders.push(order);
                            }
                        });
                    });
                    res.instructionDetails.forEach(details => {
                        details.orders = (details.orders || []).sort((a, b) => a.updated < b.updated ? -1 : 1);
                    });
                    delete res.orders;
                    res.instructionDetails.forEach(details => {
                        res.instructions.forEach(instruction => {
                            if (instruction.id === details['instruction_id']) {
                                instruction.details = instruction.details || [];
                                instruction.details.push(details);
                            }
                        });
                    });
                    res.instructions.forEach(instruction => {
                        instruction.details = (instruction.details || []).sort((a, b) => a.updated < b.updated ? -1 : 1);
                    });
                    delete res.instructionDetails;
                    setStrategyDebugInfo(res);
                    setShouldShowStrategyDebugInfoDialog(true);
                });
        };
    }

    function handleDetailsButtonClick(params) {
        return async () => {
            await fetchStrategyDetails(params.id)
                .then(res => {
                    const transformedRes = {
                        feeHistory: res.feeHistory.map(history => {
                            return {
                                ...history,
                                id: `${history.asset}:${history.created}:${history.price}:${history.strategyId}:${history.type}`,
                                createdLocalized: new Date(history.created).toLocaleString(),
                                priceFormatted: parseFloat(history.price).toFixed(6),
                                typeFormatted: history.type.replace(/_/g, ' '),
                            };
                        }),
                    };
                    setStrategyDetails(transformedRes);
                    setShouldShowStrategyDetailsDialog(true);
                });
        };
    }

    const handleStrategyIdFilterChange = _.throttle(
        e => setStrategyIdFilter(e.target.value),
        500,
        { leading: true, trailing: true },
    );

    return (
        <>
            <div>
                <div className={styles['strategy-page-title-bar']}>
                    <h3>Strategy</h3>
                    <div className={styles['strategy-id-search-box-container']}>
                        <input
                            type="number"
                            value={strategyIdFilter}
                            onChange={handleStrategyIdFilterChange}
                            placeholder="Strategy ID"
                        />
                    </div>
                </div>
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid rows={data} columns={botsColumns} pageSize={20} />
                </div>
            </div>

            <Dialog
                onClose={() => setShouldShowStrategyDebugInfoDialog(false)}
                open={shouldShowStrategyDebugInfoDialog}
                maxWidth={false}
            >
                <DialogTitle id="customized-dialog-title" onClose={() => setShouldShowStrategyDebugInfoDialog(false)}>
                    <div className={styles['debug-info-dialog__title-bar']}>
                        <span>Strategy debug info</span>
                        <div className={styles['debug-info-dialog__title-bar__close-btn']}>
                            <IconButton onClick={() => setShouldShowStrategyDebugInfoDialog(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                <div className={styles['debug-info-dialog__json-container']}>
                    <ReactJson src={strategyDebugInfo} collapsed={true} />
                </div>
            </Dialog>

            <Dialog
                fullScreen={true}
                onClose={() => setShouldShowStrategyDetailsDialog(false)}
                open={shouldShowStrategyDetailsDialog}
            >
                <div className={styles['details-dialog__appbar-container']}>
                    <AppBar position="relative">
                        <Toolbar>
                            <div className={styles['details-dialog__appbar-inner']}>
                                <Typography variant="h6">
                                    Strategy details
                                </Typography>
                                <div className={styles['details-dialog__appbar-inner__close-btn']}>
                                    <IconButton edge="start" onClick={() => setShouldShowStrategyDetailsDialog(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>
                <div className={styles['details-dialog__table-container']}>
                    {strategyDetails && (
                        <DataGrid
                            rows={strategyDetails.feeHistory}
                            columns={[
                                { field: 'strategyId', headerName: 'Strategy ID', width: 140 },
                                { field: 'createdLocalized', headerName: 'Date created', width: 200 },
                                { field: 'typeFormatted', headerName: 'Type', width: 200 },
                                { field: 'priceFormatted', headerName: 'Price', width: 200 },
                                { field: 'asset', headerName: 'Currency', width: 140 },
                            ]}
                        />
                    )}
                </div>
            </Dialog>
        </>
    )
}


export default function AdminScreen({  })
{
    return (
        <StrategyTable />
    )
    
}