import _ from 'lodash';

export function splitSpotAndFutureAmount({
    totalAmount = 0,
    leverage = 1,
    spotMarketPrice = 0.0,
    futureMarketPrice = 0.0,
    futureAmountPrecision = null,
    spotAmountPrecision = null,
    reserveRate = 0,
}){
    const maxTransferDecimalPlaces = 2;
    const maxDecimalPlaces = 12;
    // position 0 = spot, 1 = futures
    let allocation = [null, null];
    let transfer = [null, null];
    let utilized = [null, null];
    let amount = [null, null];
    let remaining = 0;
    
    const availableAmount = totalAmount * ( 1 - reserveRate );
    const estimatedQty = availableAmount / ( spotMarketPrice + futureMarketPrice / leverage );
    const amountPrecision = _.min([futureAmountPrecision, spotAmountPrecision]); // make sure spot and future buy and sell same with rounded unit
    const estimatedQtyFloor = _.isNull(amountPrecision) ? estimatedQty : _.floor(estimatedQty, amountPrecision);

    allocation[0] = totalAmount / (leverage + 1) * leverage;
    allocation[1] = totalAmount / (leverage + 1);

    transfer[0] = _.floor( totalAmount * spotMarketPrice / ( spotMarketPrice + futureMarketPrice / leverage ), maxTransferDecimalPlaces);
    transfer[1] = _.round(totalAmount - transfer[0], maxTransferDecimalPlaces);    
    
    // use estimatedQtyFloor to calculate estimate amount
    let futureAmount = estimatedQtyFloor;
    amount[1] = futureAmount;
    utilized[1] = _.round(futureAmount * futureMarketPrice / leverage, maxDecimalPlaces);

    // make sure futureAmount and spotAmount is same amount
    let spotAmount = estimatedQtyFloor;
    amount[0] = spotAmount;
    utilized[0] = _.round(spotAmount* spotMarketPrice, maxDecimalPlaces);

    remaining = _.round(totalAmount - _.sum(utilized), maxDecimalPlaces);

    return {
        totalAmount,
        remaining,
        spotAllocation: allocation[0],
        spotUtilized: utilized[0],
        spotAmount: amount[0],
        spotMarketPrice: spotMarketPrice,
        spotTradeAmount: amount[0],
        spotTransfer: transfer[0],
        futureAllocation: allocation[1],
        futureUtilized: utilized[1],
        futureAmount: amount[1],
        futureMarketPrice: futureMarketPrice,
        futureTradeAmount: amount[1],
        futureTransfer: transfer[1],
    };
}

export const MAKER_OR_TAKER = {
    MAKER: 1,
    TAKER: 2,
}
export function calcTradingFee(amount, makerOrTaker, {maker, taker}){
    if( MAKER_OR_TAKER.MAKER === makerOrTaker ){
        return amount * maker;
    }else if ( MAKER_OR_TAKER.TAKER === makerOrTaker ) {
        return amount * taker;
    }
    return null;
}

export function exchangeCrypto(
    amount, 
    toCrypto = 'USDT', 
    symbol, 
    marketingPrice
){
    const maxDecimalPlaces = 12;
    const splitter = '/';
    const splitSymbol = symbol.split(splitter);
    let result;
    let index;

    if ( !marketingPrice || !(marketingPrice >= 0) ){
        return null;
    }
    if ( splitSymbol.length !== 2 ){
        return null;
    }
    if ( ( index = splitSymbol.indexOf(toCrypto) ) === -1 ){
        return null;
    }

    console.log('index', index)
    if ( index === 0 ){
       result = amount / marketingPrice;
    } else {
       result = amount * marketingPrice;
    }
    return _.round(result, maxDecimalPlaces);
}